<script>
export default {
  name: 'List',
  props: {
    value: {
      type: [Array],
      required: true,
    },
  },
  computed: {
    dejsonedValue() {
      return JSON.parse(this.value);
    }
  }
};
</script>

<template lang="pug">
span
  ul
    li(v-for="(value, key) in dejsonedValue" :key="key") {{ key }}: {{ value }}
</template>
